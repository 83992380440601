<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="useFlagItems"
            label="LBLUSEFLAG"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            type="search"
            v-model="searchParam.useFlag">
          </c-select>
        </div> -->
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="부담작업 기준목록"
          tableId="table"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="grid.data"
                  mappingType="PUT"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'worksheetMasterManage',
  props: {
    param: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      data: {
        heaHeavyWorkNo: '',  // 부담작업no
        heavyWorkName: '',  // 부담작업명
        exposureTime: '',
        exopsureCnt: '',
        sortOrder: null,  // 순번
        useFlag: 'Y',  // 사용여부
        plantCd: null,
        regUserId: '',
        chgUserId: '',
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 40px',
            sortable: true,
          },
          {
            name: 'heavyWorkName',
            field: 'heavyWorkName',
            label: '부담<br/>작업명',
            align: 'center',
            style: 'width: 20px',
            sortable: true,
          },
          {
            name: 'exposureTime',
            field: 'exposureTime',
            label: '노출시간',
            align: 'right',
            type: 'number',
            style: 'width: 30px',
            sortable: true,
            required: true
          },
          {
            name: 'exopsureCnt',
            field: 'exopsureCnt',
            label: '노출빈도',
            align: 'right',
            type: 'number',
            style: 'width: 30px',
            sortable: true,
            required: true
          },
          {
            name: 'bodyDesc',
            field: 'bodyDesc',
            label: '신체부위',
            align: 'left',
            type: 'text',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'positionDesc',
            field: 'positionDesc',
            label: '작업자세 및 내용',
            align: 'left',
            type: 'text',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'weightDesc',
            field: 'weightDesc',
            label: '무게',
            align: 'left',
            type: 'text',
            style: 'width: 150px',
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      updateUrl: '',
      saveable: false,
      dataeditable: false,
      isSave: false,
      saveUrl: transactionConfig.hea.heavyWork.update.url,
      saveType: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // url setting
      this.listUrl = selectConfig.hea.heavyWork.list.url;
      this.updateUrl = transactionConfig.hea.heavyWork.update.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '저장하시겠습니까?<br/>*기준정보가 변경될 경우 입력중인 계획의</br>부담작업 평가결과 갱신이 필요합니다.', // 저장하시겠습니까?  MSGSAVE
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$_.forEach(this.grid.data, item => {
                item.regUserId = this.$store.getters.user.userId
                item.chgUserId = this.$store.getters.user.userId
              })
              
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
